(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('edistradadhluiApp')
        .constant('VERSION', "117.0.0")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('API_URL', "https://efaktura.dhlparcel.pl/edistrada2/")
;
})();
